<template>
    <el-dialog title="上传日志" :close-on-click-modal="false" :visible.sync="visible" width="550px">
        <el-form ref="dataForm" :model="dataForm" inline label-width="110px" label-position="right" class="border-form">
            <el-row class="set_border">
                <el-col :span="24" class="col-set_border">
                    <el-form-item label="Shift No" class="item">
                        <el-input class="selItemInput" type="input" v-model="dataForm.logNo" placeholder="请填写"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="set_border">
                <el-col :span="24" class="col-set_border">
                    <el-form-item label="日期" class="item">
                        <el-date-picker class="selItemInput" value-format="yyyy-MM-dd" v-model="dataForm.logDate"
                            type="date" style="width:300px">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row style="margin-top: 15px;"> 
                <el-form-item label="" style="width:100%">
                    <el-upload v-if="!dataForm.filePath" ref="uploadScanFile" class="upload-demo" action="action"
                        :http-request="uploadScanFile" multiple drag :show-file-list=false :auto-upload="true">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    </el-upload>
                    <div v-if="dataForm.filePath" style="margin-left: 10px;">
                        <el-button v-preventReClick type="success" size="small"
                            @click="downloadFile(dataForm.fileUrl, dataForm.logNo)">查看</el-button>
                        <el-button v-preventReClick type="primary" size="small" @click="delFile()">删除</el-button>
                    </div>
                </el-form-item>
            </el-row>
            <el-row class="formBtnGroup">
                <!-- <el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button> -->
                <el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">保存</el-button>
            </el-row>
        </el-form>
    </el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
    name: "workloginfo-preset",
    data() {
        return {
            visible: true,
            dataForm: {
                workNo: $common.getItem("workNo"),
                logDate: '',
                logNo: '',
                filePath: '',
                recNo: ''
            },
        }
    },
    methods: {
        getCurrentTime() {
            //获取当前时间并打印
            var _this = this;
            let yy = new Date().getFullYear();
            let mm = (new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1);
            let dd = (new Date().getDate()) < 10 ? '0' + (new Date().getDate()) : (new Date().getDate());
            // let dd = new Date().getDate();
            let hh = new Date().getHours();
            let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
            let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
            return yy + '-' + mm + '-' + dd;
        },
        init(id) {
            this.dataForm.recNo = id ? id : 0;
            if (id) {
                this.getDetail();
            } else {
                this.getPresetInfo()
            }
            this.visible = true
        },
        getDetail() {
            this.$http({
                url: this.$store.state.httpUrl + "/business/workloginfo/info/" + this.dataForm.recNo,
                method: "get",
                params: {},
            }).then(({
                data
            }) => {
                if (data && data.resultCode === 200) {
                    this.dataForm = data.body;
                }
            });
        },
        downloadFile(fileUri, filename) {
            if (this.dataForm.logNo == "" || this.dataForm.logNo == null || this.dataForm.logNo == undefined) {
                this.$message.error("请输入Shift No")
                return;
            }
            filename = this.getSuffix(fileUri, filename)
            this.getBlob(fileUri, (blob) => {
                this.saveAs(blob, filename)
            })
        },
        getSuffix(url, filename) {
            let lastIndex = url.lastIndexOf(".");
            return filename + url.substr(lastIndex)
        },
        getBlob(url, cb) {
            var xhr = new XMLHttpRequest()
            xhr.open('GET', url, true)
            xhr.responseType = 'blob'
            xhr.onload = function () {
                if (xhr.status === 200) {
                    cb(xhr.response)
                }
            }
            xhr.send()
        },
        saveAs(blob, filename) {
            if (window.navigator.msSaveOrOpenBlob) {
                navigator.msSaveBlob(blob, filename)
            }
            else {
                var link = document.createElement('a')
                var body = document.querySelector('body')

                link.href = window.URL.createObjectURL(blob)
                link.download = filename

                link.style.display = 'none'
                body.appendChild(link)

                link.click()
                body.removeChild(link)
                window.URL.revokeObjectURL(link.href)
            }
        },
        delFile() {
            this.dataForm.filePath = '';
            // this.dataForm.fileName = this.extractFileName(res.body.fileName);
            this.dataForm.fileUrl = '';
        },
        getPresetInfo() {
            this.$http({
                url: this.$store.state.httpUrl + "/business/worklogpreset/presetInfo/" + $common.getItem("workNo"),
                method: "get"
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.dataForm.workNo = $common.getItem("workNo")
                    // this.dataForm.recNo = data.body.recNo
                    this.dataForm.logNo = data.body.logNo
                    this.dataForm.logDate = this.getCurrentTime();
                    this.dataForm.filePath = ''
                    this.dataForm.fileUrl = ''
                }
            });
        },
        uploadScanFile(param) {
            const formData = new FormData();
            formData.append('file', param.file);
            this.$refs.uploadScanFile.clearFiles();
            this.$http.post(
                this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/95/0",
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' }
                },
            ).then(res => {
                res = res.data;
                if (res.resultCode === 200) {
                    this.dataForm.filePath = res.body.fileSavePath;
                    // this.dataForm.fileName = this.extractFileName(res.body.fileName);
                    this.dataForm.fileUrl = res.body.fileUri;
                } else {
                    this.$message.info('文件上传失败')
                }
            })
        },
        dataFormSubmit() {
            let _url = "/business/workloginfo/uploadWorkLogFile";
            this.$http({
                url: this.$store.state.httpUrl + _url,
                method: "post",
                data: this.$http.adornData(this.dataForm)
            }).then(({
                data
            }) => {
                if (data && data.resultCode === 200) {
                    this.$message({
                        message: "操作成功",
                        type: "success",
                        duration: 1500,
                        onClose: () => {
                            this.visible = false;
                            this.$parent.getDataList();
                        }
                    });
                }
            });
        },
    }
}
</script>

<style scoped="scoped">
.selItemInput.descAreaLg {
    width: 718px !important;
}

.width391 {
    width: 391px !important;
}

.addImgBox {
    width: 136px;
    height: 136px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}


.fondstyle {
    color: #3f649f;
    font-size: 15px;
}

.upload-demo /deep/ .el-upload-dragger {
    width: 200px;
    height: 115px;
}

.upload-demo /deep/ .el-upload-dragger .el-icon-upload {
    font-size: 40px;
    margin: -30px 0;
    line-height: 40px;
    padding-top: 45px
}

.upload-demo /deep/ .el-upload-dragger .el-upload__text {
    line-height: 20px;
}

.item::v-deep .el-form-item__label {
    color: #00428E !important;
    margin-right: 10px !important;
    font-weight: bold !important;
    background-color: #E1F1FF;
    white-space: nowrap !important;
    border-right: solid #dadada 1px;
}

.border-form .el-row.set_border {
    border-bottom: solid #dadada 1px;
    border-left: solid #dadada 1px;
    border-right: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
    border-top: solid #dadada 1px;
}

.el-form-item {
    align-items: stretch !important;
    margin-bottom: 0px !important;
}
</style>
